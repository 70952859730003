
import { useEffect, useState } from "react"
import ProfileLinks from "../profile-links"
import isAdminUser from '@/lib/utils/isAdminUser'
import HeaderBar from "@/components/templateux/header-bar/header-bar"
import Tabs from "@/components/templateux/tabs"
import {  tabarray_follow_details, tabarray_posts_filter } from "@/lib/config"

import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { ModalAbout } from "@/components/templateux/modal/modal-about"
import { useRouter } from "next/router"
import { useConfirm } from "@/lib/utils/hooks/useConfirm"
import { ProfileDescription } from "../profile-description"
import { getCacheDate } from "@/lib/utils/getCacheDate"
import { hasData } from "@/lib/utils/hasData"


function ProfileContainer({
     navtype
    ,subnavtype
    ,profiledata_active
    ,owner_id
    ,userdata
    ,children
    ,isAdmin
    ,openModal
    ,isOpen
    ,set_isOpen
    ,display_view
    ,set_display_view
}) {
  
  const router = useRouter()
  
 const [cache, set_cache] = useState(getCacheDate())

  const { isConfirmed } = useConfirm();

  useEffect(()=>{
    set_isOpen(openModal)
  },[
    openModal
  ])


        //Admin Lookup
        const [isAdmin_profile,set_isAdmin_profile] = useState(false)

    
        const closeModal =  async ()=>set_isOpen('')

        useEffect(()=>{
          set_isAdmin_profile(isAdminUser( profiledata_active?.id            //user_id
                                  ,profiledata_active?.bol_staff_help    //bol_staff_help
                                  ,profiledata_active?.staff_json        //staff_json
                                  ,profiledata_active?.bol_admin_help    //bol_admin_help
                                  ,userdata?.id?.toString()            //my_id
                                  ,userdata?.bol_admin?.toString()    //global_admin
                                  ,"profile"
                                  ))
        },[
           profiledata_active
          ,userdata
        ])


    const set_tab_header = (n,url) => {
      set_display_view(n);
      router.push(url, undefined, { shallow: true });
    }

    const about = (<>
                           
                            <div 
                              onClick={()=>set_isOpen('about')}
                              className="ml-2 flex md:hidden text-gray-500 border-gray-700 hover:border-gray-400 border rounded-md cursor-pointer">
                                        <SvgJsx 
                                            type={ `fill` } 
                                            icon={`information-circle-sm` } 
                                            className={` w-7 h-7 p-1 flex-0 my-auto  mx-auto`}
                                            title={'About'}
                                        />
                            </div>
                      </>)


    return (<>
    
        {/* CONTAINER */}
        <div className="">

          {/* MAIN CONTENT */}
          <div className="flex-1">
          

          <div className="flex flex-row items-top max-w-7xl mx-auto bg-gray-900
           border-x border-gray-700">

            


            {/* MAIN CONTENT */}
            <div className="flex-1">

              
      {["posts"]?.indexOf(display_view) > -1 &&
        <HeaderBar
          headertitle={
            <div className="flex items-center content-center">
              <div>
            <Tabs
            tabarray={tabarray_posts_filter(profiledata_active?.user_name,false)}
            clickval={display_view}
            set_clickval={set_tab_header}
            tab_style="selectboxhome"
          />
            </div>
            
          </div>
          
        }
          show_rss={false}
          rss_link={``}
          rss_text={''}
          action_button={about}
          show_action_button={true}
        />
        }

        {["followers","following"]?.indexOf(display_view) > -1 &&
        <div className="sticky top-[46px] z-100">
        <HeaderBar
          headertitle={<Tabs
            tabarray={tabarray_follow_details(profiledata_active?.user_name, profiledata_active?.followers, profiledata_active?.following)}
            clickval={display_view}
            set_clickval={set_display_view}
            tab_style="linkpill"
          />}
          show_rss={false}
          rss_link={``}
          rss_text={''}
          action_button={about}
          show_action_button={true}
        />
       
        </div>
        }

       

                {children}

            </div>

             {/* SIDEBAR */}
            
            <div className="  bg-gray-900 hidden md:block border-l   border-gray-700 ">
           

            <div className="flex-0 md:w-64 
                            "
            >
            {/* USED TO MAKE THE RIGHT SIDE BORDER */}
            
            <div
                className="border-b pr-5
                md:border-gray-700
                            border-transparent 
                            "
            >


            

        

            

            {profiledata_active?.bol_description?.toString() == "1" &&
            <div className="p-4">
              <ProfileDescription
                profiledata={profiledata_active}
                isAdmin={isAdmin_profile}
              />
            </div>
          }

        


            <div className="p-4 pt-0">

              <ProfileLinks 
                profiledata={profiledata_active}
                isAdmin={isAdmin_profile}
              />
            </div>

            </div>
            
            </div>



            <div
                className="  hidden md:flex h-16 px-5
                           
                            sticky top-10
                            "
            >

              <div className="flex items-center content-center flex-1 justify-center ">
              <div className="flex justify-center my-4">
                                    <div
                                        onClick={() => {
                                          window.scrollTo({top: 0, behavior: 'smooth'});
                                          // document.body.scrollTop = 0; // For Safari
                                          // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                        }}
                                        className="cursor-pointer bg-gray-400 hover:bg-gray-500 text-white font-bold text-sm italic px-4 py-1  rounded-full"
                                    >
                                    Back to Top
                                    </div>
                                </div>
              </div>
            </div>



            </div>
           


          </div>
           
          </div>

          


        </div>

        <ModalAbout
          isOpen={isOpen=='about'}
          closeModal={closeModal}
          profiledata={profiledata_active}
          ModalContent={<>
          <div>
            {profiledata_active?.bol_description?.toString() == "1" &&
              <div className="p-4">
                <ProfileDescription
                  profiledata={profiledata_active}
                  isAdmin={isAdmin}
                />
              </div>
            }

              
              
              <div className="p-4 pt-0">

                <ProfileLinks 
                  profiledata={profiledata_active}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
          </>}
        />


    
    </>)
}

export default ProfileContainer