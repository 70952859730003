import Link from 'next/link'
import Image from 'next/image'
import { hasData } from '@/lib/utils/hasData'
import SubHeader from './subheader'


export default function LinksAbout({
    links=[]
    ,headertitle='Section Header'
    ,unique_id=`links`
    ,isAdmin
    ,owner_name
}) {



  const myLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality}`
  }


    return (<>
            <div className="">
            <SubHeader
                action_url={`/${owner_name}/settings/profile?panel=userprofile#${unique_id}`}
                action_text={`edit`}
                action_condition={isAdmin}
                headertitle={headertitle}
            />
            </div>
            <ul className={`text-sm
                           text-gray-200`}>
              {links.length == 0 && 
                <li className="text-sm pt-1">Nothing here yet.</li>
              }
              {links.map((e,index) => {

                var src =`https://s2.googleusercontent.com/s2/favicons?domain_url=${e[`fieldUrl`]}`

                return (
                <li 
                  className='text-sm mt-1 flex w-full'
                  key={index}
                >
                  {/* LINKS */}
                  {(e[`fieldType`] == "links") &&
                  <>
                    {hasData(e[`fieldUrl`]) &&
                    <Link href={e[`fieldUrl`]}>
                      
                      <a 
                        target="_blank" 
                        className={` w-full max-w-sm flex flex-0 items-center content-center p-1 border border-transparent rounded-md
                                    text-blue-400 hover:text-blue-100 hover:border-gray-700
                                  `}
                        >
                        <div 
                          className="flex items-top content-top"
                        >
                          

                          {/* {e[`fieldIcon`] == 'web' || !hasData(e[`fieldIcon`])  && */}
                          {(e[`fieldIcon`]?.toString() !== "0" && hasData(e[`fieldUrl`])) &&
                          
                            <div className="flex-0 flex-shrink-0 mr-2 ">
                              <Image
                                loader={()=> myLoader({src:src,width:16,quality:100})}
                                src={src}
                                height={12}
                                width={12}
                                alt={e[`fieldName`]}
                                className="icon rounded-sm"
                                quality={100}
                                // placeholder="empty"
                                // blurDataURL={rgbDataURL(237, 181, 6)}
                                layout="intrinsic"
                              />
                            </div>
                        
                          }

                            <div className={`flex-0 `}>
                              <div className="block max-w-sm  break-words text-ellipsis overflow-hidden ">
                                {e[`fieldName`]?.split('\n')?.map((line,index) => <div key={index}>{line}</div>)}
                              </div>
                          </div>

                        </div>
                      </a>
                    </Link>
                    }
                    {!(hasData(e[`fieldUrl`])) &&
                      
                      <div
                        className={` w-full max-w-sm flex flex-0 items-center content-center p-1 border border-transparent hover:border-red-600 rounded-md
                                    cursor-pointer  bg-red-700
                                    
                                  `}
                          onClick={()=>alert('No valid URL was provided.')}
                        >
                        <div 
                          className="flex items-top content-top"
                        >

                            <div className={`text-red-600 mt-0.5 -ml-0.5`}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                <title>No URL provided.</title>
                              </svg>
                            </div>
                            <div className={`flex-0 `}>
                              <div className="block max-w-sm  break-words text-ellipsis overflow-hidden ml-2">
                                {e[`fieldName`]}
                              </div>
                            
                          </div>

                        </div>
                      </div>
                    }
                  
                  </>
                  }

                 {/* Text */}
                  {(e[`fieldType`] == "text") &&
                    
                    <div className="flex items-center content-center px-1">
                  

                      <div className={`flex-0 text-sm 
                                       text-gray-200
                                    `}>
                        <div className="block max-w-sm  break-words text-ellipsis overflow-hidden">
                          {e[`fieldName`]?.split('\n')?.map((line,index) => <div key={index}>{line}</div>)}
                        </div>

                       
                       
                      </div>
                    </div>
                  }

                {/* Header */}
                {(e[`fieldType`] == "header") &&
                    
                    <div className="flex items-center content-center px-1 w-full mt-2">
                  

                      <div className={`flex-0 w-full
                                       text-gray-200
                                    `}>
                        <h3 className="block max-w-sm  break-words text-ellipsis overflow-hidden font-bold text-md pb-1  w-full">
                          {e[`fieldName`]?.split('\n')?.map((line,index) => <div key={index}>{line}</div>)}
                        </h3>

                       
                       
                      </div>
                    </div>
                  }

                  {/* Bullet */}
                  {(e[`fieldType`] == "bullet") &&
                    
                    <div className="flex items-center content-center px-1">
                  

                      <div className={`flex-0 text-sm 
                                       text-gray-200 
                                    `}>
                        
                          <ul>
                            <li className="ml-5 list-disc">
                            <div className="block max-w-sm text-gray-200 break-words text-ellipsis overflow-hidden">
                            {e[`fieldName`]?.split('\n')?.map((line,index) => <div key={index}>{line}</div>)}
                            </div>
                            </li>
                          </ul>
                          
                       

                       
                       
                      </div>
                    </div>
                  }

                  {/* Spacer */}
                  {(e[`fieldType`] == "spacer") &&
                    
                    <div className="items-center content-center block w-full h-2" />
                  
                  }

                  {/* Line */}
                  {(e[`fieldType`] == "line") &&
                    
                    <div className="items-center content-center block w-full mt-1 border-t  border-gray-700" />
                  
                  }

                </li>
                )}
              )}
            </ul>
    </>)
}

