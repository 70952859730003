import Footer from "@/components/templateux/footer"
import LayoutPageBlank from "@/components/templateux/layout/layout-page-blank"
import NavPrimary from "@/components/templateux/nav/nav-primary"
import { config, meta } from "@/lib/config"
import { getProfileUrl } from "@/lib/utils/getProfileUrl"
import { hasData } from "@/lib/utils/hasData"
import { useContext, useEffect, useState } from "react"
import ProfileContainer from "./profile-container"
import ProfileSingleHeader from "./profile-single-header"
import isAdminUser from '@/lib/utils/isAdminUser'
import { ModalContext } from "@/components/context/modal-wrapper"


function ProfileLayout({
    navtype
    ,subnavtype=navtype
    ,helptype
    ,profiledata_active
    ,set_profiledata_active
    ,page
    ,sort
    ,dir
    ,user_name
    ,owner_id
    ,userAuthData
    ,isUserAuthLoading
    ,isUserAuthError
    ,children
    ,url=''
    ,openModal=''
    ,display_view
    ,set_display_view
    ,change_cache
    ,set_change_cache
}) {


  const {isOpen,set_isOpen} = useContext(ModalContext)





  //OLD STUFF


    const [showReminder,set_showReminder] = useState(false)   




            //Admin Lookup
            const [isAdmin,set_isAdmin] = useState(false)
            useEffect(()=>{
              set_isAdmin(isAdminUser( profiledata_active?.id            //user_id
                                      ,profiledata_active?.bol_staff_help    //bol_staff_help
                                      ,profiledata_active?.staff_json        //staff_json
                                      ,profiledata_active?.bol_admin_help    //bol_admin_help
                                      ,userAuthData?.id?.toString()            //my_id
                                      ,userAuthData?.bol_admin?.toString()    //global_admin
                                      ,helptype
                                      ))
              return () => {}
            },[
                profiledata_active
              ,userAuthData
              ,helptype
            ])

    return (<>
    {/* <Html
        lang="en"
        className={`${isOpen=='chat' ? "overflo-y-hidden" : ""}`}
      > */}
      {/* MAIN CONTAINER */}
      <LayoutPageBlank
          title={`${profiledata_active?.user_name} | ${navtype}`}
          url={`${hasData(url) ? url : getProfileUrl(process.env.NEXT_PUBLIC_SERVER_WEBSITE,profiledata_active?.user_name,navtype,page,`sort=${sort}&dir=${dir}`)}`}
          image={profiledata_active?.avatar_url}
          description={`${profiledata_active?.user_name}'s ${navtype} page on ${process.env.NEXT_PUBLIC_BRAND_NAME} | ${profiledata_active?.user_description} | ${meta.description}`}
          keywords={`${profiledata_active?.user_name},${meta.keywords}`}
      >
        
        {/* NAVBAR */}
        <NavPrimary 
            user_name={user_name}
            userAuthData={userAuthData}
            isUserAuthLoading={isUserAuthLoading}
            isUserAuthError={isUserAuthError}
            navtype={navtype}
            fixed={isOpen=='chat'}
        /> 


        {/* HEADER */}
        <ProfileSingleHeader
          user_name={user_name}
          
          userdata={userAuthData}
          profiledata_active={profiledata_active}
          set_profiledata_active={set_profiledata_active}
          owner_block_json={profiledata_active?.block_json}
          owner_staff_json={profiledata_active?.staff_json}
          owner_bol_admin_help={profiledata_active?.bol_admin_help}
          owner_bol_staff_help={profiledata_active?.bol_staff_help}
          owner_id={owner_id}
          navtype={navtype}
          helptype={helptype}
        />
 
        {/* MENUBAR */}
        {/* <ProfileMenu
            user_name={user_name}
            navtype={navtype}
            owner_id={owner_id}
            userdata={userAuthData}
            profiledata_active={profiledata_active}
        /> */}


         {/* CONTAINER */}
         <ProfileContainer
            navtype={navtype}
            subnavtype={subnavtype}
            profiledata_active={profiledata_active}
            owner_id={owner_id}
            userdata={userAuthData}
            isAdmin={isAdmin}
            openModal={openModal}
            isOpen={isOpen}
            set_isOpen={set_isOpen}
            display_view={display_view}
            set_display_view={set_display_view}
        >
          
            {children}

        </ProfileContainer>
        


        {/* HIDE FOOTER WHEN CHAT FULL SCREEN IS ON TO PREVENT DARK MODE CONFLICTS */}
        {/* {(!fullScreen && navtype !== "chat") && */}
        {["chat","replay"].indexOf(isOpen) == -1 && <Footer />}
        {/* } */}
      </LayoutPageBlank>

      {/* </Html> */}
      </>)
}

export default ProfileLayout