
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AuthorCredit from "../user/author-credit";

export const ModalAbout = ({
     isOpen
    ,closeModal
    ,ModalContent
    ,profiledata
}) => {


   


    return (<>
                    <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                    as="div"
                    open={isOpen}
                    className="fixed inset-0 z-[100] overflow-y-scroll    bg-[rgb(0,0,0,0.85)]"
                    onClose={()=>closeModal()}
                    >
                    
                    <div className="min-h-screen px-5 max-w-xl mx-auto text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full mb-10 border transform pb-1 mt-[48px] overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                        bg-gray-900  border-gray-700">
                            
                            
                            
                            {/* USERS */}
                            
                                <div className="w-full content-center item-center flex py-2 px-4 shadow-md  bg-gray-700 border-b  border-gray-600">
                                    <div className="flex-1 font-bold text-xl">
                                        <AuthorCredit
                                          display_data={profiledata}
                                          isPlaying={false}
                                          profiledata={profiledata}
                                          show_avatar={true}
                                          show_user_name={true}
                                          show_flair={false}
                                          custom_flair=''
                                          bol_mod={0}
                                          bgClass='w-8 h-8'
                                          containerClassBig='w-9 h-9 '
                                          containerClassSmall='w-9 h-9 '
                                          avatarClass='w-8 h-8 border-2 border-transparent '
                                          rotaryContainerClass='w-8 h-8 mr-4'
                                          rotaryInternalClass='w-8 h-8'
                                          sizes={{
                                            width:50
                                            ,height:50
                                            ,quality:50
                                          }}
                                        />
                                    </div>
                                    
                                    <div className='flex-0'>
                                        <button
                                            type="button"
                                            tabIndex={0}
                                            className="inline-flex justify-center px-2 py-1 text-md font-medium text-white bg-blue-500 hover:bg-blue-400  border border-transparent rounded-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-2 focus-visible:ring-white"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                
                                </div>
                         

                            
                            <div className=" w-full  overflow-y-auto content-center item-center flex">
                           


                                {ModalContent}
                            

                            
                            </div>
                            

                            <div className="
                            ">
                            
                           
                            </div>
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>
    
    
    </>)
}