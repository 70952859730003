import { meta } from "@/lib/config"
import Link from "next/link"
import Footer from "../templateux/footer"
import LayoutPageBlank from "../templateux/layout/layout-page-blank"
import NavPrimary from "../templateux/nav/nav-primary"
import ProfileMenu from "./item/profile-menu"
import ProfileSingleHeader from "./item/profile-single-header"



function ProfileMissing({
     user_name
    ,userAuthData
    ,isUserAuthLoading
    ,isUserAuthError
    ,navtype
    ,helptype
}) {

    return (
<>
            {/* MAIN CONTAINER */}
             <LayoutPageBlank
                title={`${user_name} | No such user! | ${navtype}`}
                url={`${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/${user_name}`}
                image={meta.image}
                description={`${user_name}'s ${navtype} page on ${process.env.NEXT_PUBLIC_BRAND_NAME} | ${meta.description}`}
                keywords={`${user_name},${meta.keywords}`}
              
            >
              
                <NavPrimary
                  user_name={user_name}
                  userAuthData={userAuthData}
                  isUserAuthLoading={isUserAuthLoading}
                  isUserAuthError={isUserAuthError}
                  navtype={navtype}
                  />

                  {/* HEADER */}
                  <ProfileSingleHeader
                    user_name={user_name}
                    userdata={{}}
                   
                    profiledata_active={{}}
                    set_profiledata_active={()=>{}}
                    owner_block_json={[]}
                    owner_staff_json={[]}
                    owner_bol_admin_help={0}
                    owner_bol_staff_help={0}
                    owner_id={0}
                    navtype={navtype}
                    helptype={helptype}
                  />






                          {/* MENUBAR */}
                  {/* <ProfileMenu
                      user_name={user_name}
                      navtype={navtype}
                      owner_id={0}
                      userdata={{}}
                      profiledata_active={{}}
                  /> */}
                
                    <div className=" flex text-center items-center contents-center justify-center my-20 mx-auto">
                      <div>
                        <div className=" text-white">
                            <h1 className="font-bold text-2xl">
                              No such user!
                            </h1>
                          </div>
                          <div className=" text-white">
                            <span className="block ">Hopefully you find what you are looking for...</span>
                          </div>
                          <div className='mt-5'>
                            <Link href='/directory?page=1&amp;sort=followers&amp;dir=down'>
                              <a className='underline hover:no-underline 
                                           text-blue-500 hover:text-white 
                                          '>
                                View the members directory
                              </a>
                            </Link>
                          </div>
                          <div className='mt-5'>
                            <Link href='/'>
                              <a className='underline hover:no-underline 
                                          text-blue-500 hover:text-white 
                                          '>
                                Return to {process.env.NEXT_PUBLIC_BRAND_NAME}
                              </a>
                            </Link>
                          </div>

                      </div>
                    </div>
              
                <Footer />
            </LayoutPageBlank>
            </>)
}

export default ProfileMissing